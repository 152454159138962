import React from 'react'

export default function Practice() {
  return (
    <>
   <div style={{color:'white'}}>This is Practice Component</div>
    
    
    </>

  )
}
